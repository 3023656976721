import { Chip, lighten, makeStyles, Theme } from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";
import clsx from "clsx";
import React, { useMemo } from "react";
import { useSubscription } from "../hooks/useSubscription";
import { NotificationSyncStatus, TransformDomain } from "../reclaim-api/types";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.success.dark,
    backgroundColor: lighten(theme.palette.success.light, 0.8),
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiChip-label": {
        paddingRight: 0,
      },
    },
  },
  rootServerSync: {
    color: theme.palette.primary.dark,
    backgroundColor: lighten(theme.palette.primary.light, 0.8),
  },
  label: {
    [theme.breakpoints.down("xs")]: {
      width: 0,
    },
  },
  spin: {
    width: 20,
    height: 20,
    animationName: "spin",
    animationDuration: "3000ms",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
    color: theme.palette.success.main,
  },
  spinServerSync: {
    color: theme.palette.primary.main,
  },
}));

export const SyncStatus: React.VFC = () => {
  const classes = useStyles();
  const syncStatus = useSubscription(() => TransformDomain.syncStatus$);

  const label = useMemo(() => {
    switch (syncStatus) {
      case NotificationSyncStatus.SyncingServer:
        return "Syncing Reclaim";
      case NotificationSyncStatus.SyncingCalendar:
        return "Syncing Calendar";
      case NotificationSyncStatus.Ready:
      default:
        return "Done syncing";
    }
  }, [syncStatus]);

  const serverSyncing = syncStatus === NotificationSyncStatus.SyncingServer;

  return (
    <>
      {!!syncStatus && syncStatus !== NotificationSyncStatus.Ready && (
        <Chip
          className={clsx(classes.root, { [classes.rootServerSync]: serverSyncing })}
          icon={
            <SyncIcon
              className={clsx(classes.spin, {
                [classes.spinServerSync]: serverSyncing,
              })}
            />
          }
          classes={{
            label: classes.label,
          }}
          label={label}
        />
      )}
    </>
  );
};
